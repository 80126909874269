import React from "react"
import Layout from "../components/Layouts/Layout"
import SEO from "../components/SEO"
import ProjectsInProgress from "../components/ProjectsInProgress"
import { VerticalSpace } from "../components/VerticalSpace"
import ContactUsForm from "../components/ContactUsForms"

const description = "Creative Ilk was created by a digital marketer and an engineer to solve some of the most frustrating problems in effective email marketing. We create free and premium tools to help you grow and effectively advertise your business. Get creative with us."

const AboutUs: React.FunctionComponent = () => (
  <Layout title="Once upon a time, an engineer met a marketer...">
    <SEO title="About Us" description={description} />

    <div className="py-5">
      <p>
        Hi! We're Caitlin and Ryan, an engineer-and-marketing duo that loves all things automation. The day the robots take over, our work will be done!
      </p>
      <p>
        When we’re not binge watching something on Netflix (adventurous, aren't we?), we're arguing about the conjoined triangles of success or ideating yet another side project idea.
      </p>
      <p>
        Creative Ilk is one such side project, born of our desire to do something productive in our leisure time that also helped at least one of us in our day jobs. Having both worked for small businesses and big corporates, we’ve both seen far too many antiquated systems and process, requiring manual drudgery. Ain't nobody got time for that.
      </p>
      <p>
        That’s why we do what we do! We build small tools to save ourselves and other side-hustlers, small businesses, and kindred spirits from the same soul-crushing, hair-tearing frustration we’ve faced in our previous projects.
      </p>
      <p>
        We hope some of our free tools come in handy. If you need more help for your hustle, we're also available for consulting for anything from email delivery to automated reporting. Get in contact with us and we can help you with your own martech story.
      </p>
    </div>
    <ContactUsForm />
    <VerticalSpace height={100} />
    <div>
      <h3>
        Free tools we hope you'll love
      </h3>
      <ProjectsInProgress />
    </div>

    <VerticalSpace height={50} />
    <div>
      <h3>
        You've almost reached the end of the page
      </h3>
      <p>
        Since you made it all the way down here, we'll answer a frequently asked question. We chose the name Creative Ilk, knowing that we wanted to be creative and build various tools for growth hackers, marketers and others <i>of that ilk</i>.
      </p>
      <p>
        As it turns out, the modern use of that phrase (relating to 'type' or 'sort') is considered incorrect by traditionalists. Back in the day, the Scottish used the phrase 'of that ilk' to avoid repitition in a person's title. For example, 'Lachlan MacLachlan of MacLachlan' could simply be referred to as 'MacLachlan of that ilk'.
      </p>
      <p>
        While we've never claimed to be etymologists (we just build tools for more efficient marketing!), we do think it's serendipitous that 'ilk' itself has been used for centuries for the express purpose of efficiency.
       </p>
    </div>

    <VerticalSpace height={100} />

  </Layout >
)

export default AboutUs