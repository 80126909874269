import React from "react";
import { Link } from "gatsby";
import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalculator, faEnvelope, faCode } from '@fortawesome/free-solid-svg-icons'

interface Project {
  icon: IconDefinition
  title: string
  href: string
  description?: string
}

const projects: Project[] = [
  // {
  //   icon: 'far fa-envelope',
  //   title: 'Email code creator',
  //   description: `
  //     Email marketing is valuable, there's no doubt about it. It is one of the most cost
  //     effective channels, allowing you to communicate with customers, remain top of mind, and drive
  //     conversions. It's also notoriously time-intensive and finnicky. We're making email
  //     easy.
  //   `
  // },
  {
    icon: faCalculator,
    title: 'Statistical significance calculator',
    href: '/tools/statistical-significance-calculator/',
    description: `
      A test and learn framework can make more a world of difference to your bottom-line results. We're
      building a statistical significance calculator so that you can compile, record and analyse all your
      various A/B and multivariate tests from different channels.
    `
  },
  {
    icon: faEnvelope,
    title: 'Email A/B test calculator',
    href: '/tools/email-ab-test-calculator/',
    description: `
      This test calculator is specifically made for email marketers! Run your test metrics through the calculator 
      to determine any statistically significant results, including across opens, clicks, conversions, and unsubscribes.
    `
  },
  {
    icon: faCode,
    title: 'CSS Inliner',
    href: '/tools/css-inliner/',
    description: `
      A CSS inliner is a tool primarily used while creating HTML emails. HTML emails tend to require
      a lot of duplication of styles for each element. A CSS inliner can take this incredibly manual,
      mind-numbing task and automate it for you.
    `
  }
]

const ProjectsInProgress: React.FunctionComponent = () => (
  <div className="row pb-5">
    {projects.map(({ icon, title, href, description }) => (
      <div className="col-lg-6 py-3" key={href}>
        <Link to={href} className="text-decoration-none">
          <div className="card h-100">
            <div className="card-body">
              <h5 className="card-title text-primary text-center">
                <div>
                  <FontAwesomeIcon icon={icon} />
                </div>
                {title}
              </h5>
              <p className="card-text text-dark">
                {description}
              </p>
              <p className="text-center text-primary text-bold">
                Use it
              </p>
            </div>
          </div>
        </Link>
      </div>
    ))}
  </div>
)

export default ProjectsInProgress