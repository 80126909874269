import React from 'react'

interface VerticalSpaceProps {
  height: number
}

export const VerticalSpace: React.FunctionComponent<VerticalSpaceProps> = ({ height }) => {
  const style = React.useMemo(() => ({
    height
  }), [height])
  return <div style={style} />
}