import React from "react";
import { VerticalSpace } from "../VerticalSpace";

const ContactUsForm: React.FunctionComponent = () => {
  return null
  return (
    <React.Fragment>
      <form>
        <div className="form-group">
          <label htmlFor="contactFormNameInput">Your name</label>
          <input
            type="text"
            className="form-control"
            id="contactFormNameInput"
            placeholder="Nice to meet you!" />
        </div>

        <div className="form-group">
          <label htmlFor="contactFormEmailInput">Your email address</label>
          <input
            type="email"
            className="form-control"
            id="contactFormEmailInput"
            placeholder="Because smoke signals aren't as effective"
            required />
        </div>

        <div className="form-group">
          <label htmlFor="exampleFormControlTextarea">How can we help you?</label>
          <textarea className="form-control" id="exampleFormControlTextarea" rows={8} required></textarea>
        </div>


        <VerticalSpace height={40} />

        <button type="submit" className="btn btn-primary btn-lg">Get in touch</button>
      </form>

    </React.Fragment>
  )
}

export default ContactUsForm