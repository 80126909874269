import React from "react"
import Navbar from "./Navbar"
import Footer from "./Footer"

interface LayoutProps {
  title: string
  className?: string
}

const Layout: React.FunctionComponent<LayoutProps> = ({ title, className = "", children }) => {
  return (
    <React.Fragment>
      <Navbar />
      <header className="bg-primary text-white">
        <div className="container text-center py-5">
          <h1>{title}</h1>
        </div>
      </header>
      <main className={`container mt-3 flex-grow-1 ${className}`}>
        {children}
      </main>
      <Footer />
    </React.Fragment>
  )
}

export default Layout
